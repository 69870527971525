import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import StoryCard from '../components/StoryCard'
import StoryCardItem from './StoryCardItem'

const StoriesGrouped = ({ data }) => (
  <div className="column is-12">
    <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
      All the Stories
    </h2>
    {data.allMdx.group.map(({ fieldValue, edges, index }) => {
      return (
        <>
          <StoryCard
            key={index}
            title={fieldValue}
            content={edges.map(({ node }) => {
              return (
                <StoryCardItem
                  key={node.id}
                  title={node.frontmatter.title}
                  content={node.frontmatter.description}
                  linkCp={
                    node.fields.slug.substr(0, node.fields.slug.length -1) +
                    '-cp/'
                  }
                  linkFp={
                    node.fields.slug.substr(0, node.fields.slug.length - 1) +
                    '-fp/'
                  }
                  linkMp={
                    node.fields.slug.substr(0, node.fields.slug.length - 1) +
                    '-mp/'
                  }
                />
              )
            })}
          />{' '}
          <br />
        </>
      )
    })}
  </div>
)

StoriesGrouped.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query StoriesGroupedQuery {
        allMdx(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "stories-post" } } }
        ) {
          group(field: frontmatter___teddyname) {
            edges {
              node {
                frontmatter {
                  teddyname
                  title
                  description
                }
                fields {
                  slug
                }
                id
              }
            }
            fieldValue
          }
        }
      }
    `}
    render={(data) => <StoriesGrouped data={data} />}
  />
)
