import React from 'react'
import { Link } from 'gatsby'

export const StoryCardItem = ({ title, content, linkMp, linkFp, linkCp }) => {
  return (
    <div className="card block">
      <div className="card-content">
        <p className="has-text-weight-bold is-size-4">{title}</p>
        <p>{content}</p>
      </div>
      <footer className="card-footer">
        <p className="card-footer-item">
        <span>
            <Link to={linkCp}>Custom Characters</Link>
          </span>
          </p>
          <p className="card-footer-item">
          <span>
            <Link to={linkFp}>Penny</Link>
          </span>
        </p>
        <p className="card-footer-item">
          <span>
            <Link to={linkMp}>Max</Link>
          </span>
        </p>
      </footer>
    </div>
  )
}

export default StoryCardItem
