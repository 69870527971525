import React from 'react'
import bulmaCollapsible from '@creativebulma/bulma-collapsible/src/js/index.js'

class StoryCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = { label: '+' }
  }

  //toggle button label
  changeText = (label) => {
    if (this.state.label === '+') {
      this.setState({ label: '-' })
    } else {
      this.setState({ label: '+' })
    }
  }

  componentDidMount() {
    this.collapsibles = bulmaCollapsible.attach('.is-collapsible', {
      container: this.refs.collapsibles,
    })
  }

  render() {
    return (
      <div ref="collapsibles" id="accordion_first">
        <article className="message">
          <div className="message-header">
            <p>
              <span>{this.props.title} </span>
            </p>
            <p>
              <span>
                <a
                  href="#collapsible-message-accordion-1"
                  data-action="collapse"
                  className="button is-family-monospace"
                  onClick={() => {
                    this.changeText('-')
                  }}
                >
                  {this.state.label}
                </a>
              </span>
            </p>
          </div>
          <div
            id="collapsible-message-accordion-1"
            className="message-body is-collapsible"
            data-parent="accordion_first"
            data-allow-multiple="true"
          >
            <div className="message-body-content">{this.props.content}</div>
          </div>
        </article>
      </div>
    )
  }
}

export default StoryCard
