import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

class StoriesList extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMdx

    return (
      <div className="columns is-multiline">
        <div className="column is-12">
          <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
            Latest Stories
          </h2>
        </div>
        {posts &&
          posts.map(({ node: post }) => (
            <div
              className="column is-12 card box has-background-light"
              key={post.id}
            >
              <div className="card-image">
                <figure className="image">
                  <Img
                    fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
                    style={{ borderRadius: 5 }}
                  />
                </figure>
              </div>
              <div className="card-content">
                <p className="title is-size-4">{post.frontmatter.title}</p>
                <p className="subtitle is-size-6">
                  {post.frontmatter.teddyname +
                    ' | ' +
                    'Read aloud time: ' +
                    post.frontmatter.readtime}
                </p>
                <p className="is-centered">{post.frontmatter.description}</p>
              </div>
              <footer className="card-footer">
              <Link
                  className="card-footer-item button has-text-link"
                  to={post.fields.slug}
                >
                  Read {post.frontmatter.title}
                </Link>
              </footer>
            </div>
          ))}
      </div>
    )
  }
}

StoriesList.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query StoriesListQuery {
        allMdx(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "stories-post" } } }
          limit: 20
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                teddyname
                title
                description
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                readtime
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 1500, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <StoriesList data={data} count={count} />}
  />
)
